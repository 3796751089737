<template>
  <b-modal
    id="deleteColumnModal"
    ref="deleteColumnModal"
    centered
    content-class="fu-modal"
    header-class="fu-modal__header fu-modal__delete-header"
    body-class="fu-modal__body"
    footer-class="fu-modal__footer"
    @hidden="closeModal"
  >
    <template #modal-header>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center col-12 p-0"
      >
        <div class="col-lg-8 col-10 p-0">
          <h5 class="mb-0">
            Delete: <b>{{ column.name }}</b>
          </h5>
        </div>
        <button
          @click="$bvModal.hide('deleteColumnModal')"
          class="fu-modal__header-close-button"
        >
          <i class="fal fa-times fa-2x"></i>
        </button>
      </div>
    </template>

    <div
      v-if="columnDeals && columns.length >= 1"
      class="d-flex flex-wrap justify-content-center col-12 px-0 mt-3"
    >
      <span class="text-center"
        >Before deleting column, please move its deals to selected column:</span
      >
      <div class="col-8 px-0">
        <select
          v-model="newColumnIndex"
          class="fu-login__input fu-select mt-3"
          :class="{ 'fu-select__disabled': isLoading }"
        >
          <option
            v-for="(value, index) in columns"
            :key="index"
            :value="index + 1"
          >
            {{ value.name }}
          </option>
        </select>
      </div>
    </div>

    <div
      v-else-if="columnDeals && !columns.length"
      class="d-flex flex-wrap justify-content-center col-12 px-0 mt-3"
    >
      <span class="text-center"
        >Lead board must have <b>at least two</b> columns if you want to delete
        this column</span
      >
    </div>

    <template #modal-footer>
      <fu-button
        ref="deleteColumnButton"
        title="Delete"
        id="deleteColumnButton"
        :is-loading="isLoading"
        :disabled="isLoading || (!newColumnIndex && columnDeals !== 0)"
        @click="moveDeals"
      ></fu-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "LeadBoardDeleteColumnModal",
  data() {
    return {
      newColumnIndex: null,
      isLoading: false,
    };
  },
  computed: {
    columns() {
      return this.$store.state.columns.columns.filter(
        (value) => value.id !== this.column.id
      );
    },
    leadBoardId() {
      return this.$store.state.columns.leadBoard.id;
    },
    column() {
      return this.$store.state.columns.selectedColumn;
    },
    columnDeals() {
      return Object.values(this.column.deals ? this.column.deals : {}).length;
    },
  },
  methods: {
    closeModal() {
      this.newColumnIndex = null;
      this.$store.commit("columns/SET_SELECTED_COLUMN", {});
      this.$refs.deleteColumnModal.hide();
    },
    async moveDeals() {
      try {
        this.isLoading = true;
        if (this.newColumnIndex && this.columnDeals !== 0) {
          const newColumn = this.columns[this.newColumnIndex - 1];
          const movedDeals = this.column.deals.map((deal) => {
            return deal.id;
          });
          await this.$axios.put(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/deals/bulk-move`,
            { columnId: newColumn.id, deals: movedDeals }
          );
          await this.column.deals.map((deal) => {
            newColumn.deals.push(deal);
          });
          await this.deleteColumn();
        } else if (!this.columnDeals) {
          await this.deleteColumn();
        }
      } catch (error) {
        console.error(error);
        this.$bvToast.toast("Deleting column failed", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deleteColumn() {
      await this.$axios.delete(
        process.env.VUE_APP_API_URL +
          `/api/lead-boards/${this.leadBoardId}/columns/${this.column.id}`
      );
      await this.$store.commit(
        "columns/DELETE_LEAD_BOARD_COLUMN",
        this.column.id
      );
      this.$bvToast.toast("Deleting column successful", {
        title: "Deleting column successful",
        variant: "success",
        solid: true,
      });
      this.closeModal();
    },
  },
};
</script>
