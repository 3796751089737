<template>
  <div class="d-flex justify-content-end w-100">
    <div
      v-if="value.rules.length"
      class="d-flex align-items-center w-100 mb-1 mr-3"
    >
      <i
        v-b-tooltip.hover
        data-toggle="tooltip"
        data-placement="right"
        title="This column has a rule set"
        class="far fa-exclamation-circle mr-2 fu-large fu-lead__column-rule"
      ></i>
    </div>
    <button
      v-if="index !== 0"
      class="fu-button__transparent fu-lead__button-switch__column kt-button-lead-column-move-left"
      @click="moveColumn('left', value)"
    >
      <i class="fal fa-arrow-square-left fa-2x"></i>
    </button>
    <button
      v-if="index !== columnsLength - 1"
      class="fu-button__transparent fu-lead__button-switch__column kt-button-lead-column-move-right"
      @click="moveColumn('right', value)"
    >
      <i class="fal fa-arrow-square-right fa-2x"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "LeadBoardColumnNavigation",
  props: {
    index: {
      type: Number,
      required: true,
    },
    leadBoardId: {
      type: Number,
      required: true,
    },
    columnsLength: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async moveColumn(direction, columnData) {
      try {
        const newPosition =
          direction === "right"
            ? columnData.position + 1
            : columnData.position - 1;
        await this.$axios.put(
          `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/columns/${columnData.id}`,
          {
            name: columnData.name,
            position: newPosition,
          }
        );
        await this.$store.commit("columns/CHANGE_LEAD_BOARD_COLUMN_POSITION", {
          newPosition,
          column: columnData,
        });

        this.$bvToast.toast(`Column ${columnData.name} is moved`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: "Changing column position failed",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>
