<template>
  <main>
    <b-overlay
      :show="fetching"
      class="position-relative d-flex flex-wrap justify-content-center col-12 fu-lead"
    >
      <lead-board-header
        :columns="columns"
        @openCreateDealSidebar="openCreateDealSidebar()"
        @openQuestionnaireSidebar="openQuestionnaireSidebar()"
        @dealOwner="selectedDealOwner = $event"
      ></lead-board-header>

      <div ref="leadWrapper" class="d-flex col-12 px-0 mt-4 fu-lead__wrapper">
        <!-- SINGLE LEAD COLUMN -->
        <div
          :id="value.id"
          v-for="(value, index) in columns"
          :key="index"
          class="d-flex flex-column align-items-center px-0 mb-4 fu-lead__column"
          :class="{ 'pl-3': index !== 0 }"
        >
          <lead-board-column-navigation
            :index="index"
            :columns-length="columns ? columns.length : 0"
            :lead-board-id="leadBoardId"
            :value="value"
          ></lead-board-column-navigation>

          <div class="card w-100 fu-lead__card">
            <!-- CARD HEADER -->
            <lead-board-column-header
              :lead-board-id="leadBoardId"
              :column-id="value.id"
              :title="value.name"
              :column-position="value.position"
              :deals-length="value.deals ? value.deals.length : 0"
              @deleteColumn="deleteColumn(columns[index])"
            ></lead-board-column-header>
            <!-- CARD HEADER -->

            <!-- INNER CARDS -->
            <lead-board-column-card
              :ref="`wrapper${value.id}`"
              :column-index="index"
              :columns="columns"
              :card-position="value.position"
              :column-cards="value.deals"
              :lead-board-id="leadBoardId"
              @scrollColumn="scrollColumn($event)"
            ></lead-board-column-card>
            <!-- INNER CARDS -->

            <!-- COLUMN FOOTER -->
            <lead-board-column-footer
              @addNewColumnDeal="openCreateDealSidebar(value.id)"
            ></lead-board-column-footer>
            <!-- COLUMN FOOTER -->
          </div>
        </div>

        <div v-if="columns.length" class="ml-3" style="margin-top: 35px">
          <button
            id="kt-button-lead-column-add"
            class="d-flex align-items-center fu-button__transparent fu-lead__button-add-column"
            @click="addNewColumn"
          >
            <i class="fal fa-plus fu-large p-1"></i>
          </button>
        </div>

        <div
          v-if="!columns.length && !fetching"
          class="d-flex flex-column align-items-center justify-content-center w-100 h-100"
        >
          <h2>Selected lead board doesn't have any columns yet.</h2>
          <span class="fu-lead__empty">&#128530;</span>
          <button class="fu-contact__button" @click="addNewColumn">
            <span><i class="far fa-plus mr-2 mt-3"></i>Add new column</span>
          </button>
        </div>
        <!-- SINGLE LEAD COLUMN -->
      </div>
      <lead-board-create-questionnaire-sidebar></lead-board-create-questionnaire-sidebar>

      <lead-board-column-rule-sidebar></lead-board-column-rule-sidebar>

      <lead-board-create-deal-sidebar
        :selected-deal-owner="selectedDealOwner"
        @resetColumnId="columnId = null"
      ></lead-board-create-deal-sidebar>

      <lead-board-delete-column-modal></lead-board-delete-column-modal>
    </b-overlay>
  </main>
</template>

<script>
import LeadBoardHeader from "@/components/lead-board/LeadBoardHeader";
import LeadBoardColumnHeader from "@/components/lead-board/LeadBoardColumnHeader";
import LeadBoardColumnFooter from "@/components/lead-board/LeadBoardColumnFooter";
import LeadBoardColumnNavigation from "@/components/lead-board/LeadBoardColumnNavigation";
import LeadBoardColumnRuleSidebar from "@/components/lead-board/sidebars/LeadBoardColumnRuleSidebar";
import LeadBoardColumnCard from "@/components/lead-board/LeadBoardColumnCard";
import LeadBoardCreateDealSidebar from "@/components/lead-board/sidebars/LeadBoardCreateDealSidebar";
import LeadBoardDeleteColumnModal from "@/components/lead-board/modal/LeadBoardDeleteColumnModal";
import LeadBoardCreateQuestionnaireSidebar from "@/components/lead-board/sidebars/LeadBoardCreateQuestionnaireSidebar";

export default {
  name: "LeadBoard",
  components: {
    LeadBoardCreateQuestionnaireSidebar,
    LeadBoardDeleteColumnModal,
    LeadBoardCreateDealSidebar,
    LeadBoardColumnCard,
    LeadBoardColumnRuleSidebar,
    LeadBoardColumnNavigation,
    LeadBoardColumnFooter,
    LeadBoardColumnHeader,
    LeadBoardHeader,
  },
  data() {
    return {
      selectedDealOwner: -1,
      fetching: false,
    };
  },
  computed: {
    leadBoardId() {
      return Number(this.$route.params.id);
    },
    columns() {
      return this.$store.state.columns.columns;
    },
    columnId: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"]("columnId");
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "columnId",
          fieldValue,
        });
      },
    },
  },
  created() {
    this.fetchLeadBoardColumns();
  },
  beforeDestroy() {
    this.$store.commit("columns/SET_LEAD_BOARD_COLUMNS", []);
    this.$store.commit("columns/SET_LEAD_BOARD_DATA", {});
  },
  methods: {
    scrollColumn(id) {
      const wrapper = this.$refs[`wrapper${id}`][0].$el;
      this.$nextTick(() => {
        wrapper.scrollTo(0, wrapper.scrollHeight);
      });
    },
    deleteColumn(column) {
      this.$store.commit("columns/SET_SELECTED_COLUMN", column);
      this.$bvModal.show("deleteColumnModal");
    },
    openQuestionnaireSidebar() {
      this.$root.$emit("bv::toggle::collapse", "createQuestionnaireSidebar");
    },
    openCreateDealSidebar(id = null) {
      if (this.columns.length) {
        this.columnId = id ? id : this.columns[0].id;
        this.$root.$emit("bv::toggle::collapse", "createDealSidebar");
      }
    },
    async addNewColumn() {
      try {
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/columns`,
          {
            name: "New column",
          }
        );
        await this.$store.commit("columns/ADD_LEAD_BOARD_COLUMN", res.data);
        this.$bvToast.toast("New column added", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        const wrapper = this.$refs.leadWrapper;
        wrapper.scrollTo(wrapper.scrollWidth, 0);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: "Adding column failed",
          variant: "danger",
          solid: true,
        });
      }
    },
    async fetchLeadBoardColumns() {
      try {
        this.fetching = true;

        const [meta, columns, leadBoard, questionnaires] = await Promise.all([
          await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/meta`
          ),
          await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/columns`
          ),
          await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}`
          ),
          await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/questionnaires`
          ),
        ]);

        if (
          !meta.data.users.filter(
            ({ id }) => id === this.$store.state.loggedUser.id
          ).length
        )
          meta.data.users.push(this.$store.state.loggedUser);
        this.$store.commit("columnDeal/SET_DEAL_META", meta.data);
        meta.data.users.push(
          { id: -2, firstName: "Unassigned", lastName: "" },
          { id: -1, firstName: "All", lastName: "" }
        );
        this.$store.commit("columnDeal/SET_DEAL_OWNERS", meta.data.users);
        this.$store.commit("columns/SET_LEAD_BOARD_COLUMNS", columns.data);
        this.$store.commit("columns/SET_LEAD_BOARD_DATA", leadBoard.data);
        this.$store.commit(
          "questionnaire/SET_QUESTIONNAIRES_DATA",
          questionnaires.data
        );
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: "Fetching lead board columns failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
