<template>
  <b-sidebar
    id="createQuestionnaireSidebar"
    ref="createQuestionnaireSidebar"
    right
    backdrop
    sidebar-class="fu-sidebar fu-sidebar__user"
    header-class="fu-sidebar__header"
    body-class="fu-sidebar__body flex-column"
    footer-class="fu-sidebar__footer"
    @hidden="closeSideBar"
  >
    <template #header>
      <span>Create questionnaire</span>
      <i class="far fa-times fa-1x" @click="closeSideBar"></i>
    </template>

    <validation-observer
      ref="questionnaireForm"
      class="d-flex flex-wrap col-12 px-0 mt-3"
    >
      <div
        class="d-flex flex-wrap align-items-center justify-content-between col-12 px-0"
      >
        <validation-provider
          tag="div"
          name="questionnaire"
          v-slot="{ errors }"
          class="col-6 px-0 pr-lg-3 pr-0"
        >
          <multiselect
            v-model="questionnaireId"
            :options="questionnaires"
            :loading="isLoading"
            label="name"
            track-by="name"
            placeholder="Select questionnaire"
            class="multiselect"
            :class="{
              multiselect__disabled: isLoading,
            }"
            :disabled="isLoading"
            @search-change="searchValue = $event"
          >
            <template slot="placeholder"> Select questionnaire </template>

            <template slot="option" slot-scope="props">
              <span class="kt-select-item">{{ props.option.name }}</span>
            </template>

            <template slot="noResult">
              <span>No country found: "{{ searchValue }}" </span>
            </template>
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>

        <button class="fu-contact__button" @click="createNewQuestionnaire">
          Create new
        </button>
      </div>

      <validation-provider
        tag="div"
        name="title"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0 mt-3 mb-4"
      >
        <span class="w-100 font-weight-bold">Title *</span>
        <br />
        <input
          v-model="questionnaire.name"
          type="text"
          name="title"
          placeholder="Title"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <div
        v-for="(question, index) in questionnaire.questions"
        :key="index"
        class="d-flex flex-wrap align-items-center col-12 px-0 mb-4 fu-border__lightgrey-top"
      >
        <div class="d-flex flex-wrap align-items-center col-12 px-0">
          <validation-provider
            tag="div"
            name="question type"
            rules="required"
            v-slot="{ errors }"
            class="col-6 px-0 mt-0"
          >
            <span class="w-100 font-weight-bold">Question type</span><br />
            <select
              :value="question.typeId"
              @change="setQuestionType(question, Number($event.target.value))"
              class="pl-3 text-capitalize fu-login__input fu-select"
              :class="{ 'fu-select__disabled': isLoading }"
              :disabled="isLoading"
            >
              <option
                v-for="option in questionTypes"
                :key="option.id"
                :value="option.id"
                class="text-capitalize"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <div
            class="d-flex flex-column align-items-end justify-content-between col-6 px-0 pt-3"
          >
            <button
              class="mb-2 fu-button__transparent fu-button__position"
              @click="changeQuestionPosition(question, 'up')"
              :disabled="question.index === 0"
            >
              <i class="far fa-chevron-up"></i>
            </button>
            <button
              v-show="questionnaire.questions.length > 1"
              class="fu-button__transparent fu-button__position"
              @click="deleteQuestion(index)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
            <button
              class="mt-2 fu-button__transparent fu-button__position"
              @click="changeQuestionPosition(question, 'down')"
              :disabled="question.index === questionnaire.questions.length - 1"
            >
              <i class="far fa-chevron-down"></i>
            </button>
          </div>
        </div>

        <validation-provider
          tag="div"
          :name="`question ${index + 1}`"
          rules="required|max:400"
          v-slot="{ errors }"
          class="col-12 px-0 mt-1"
        >
          <span class="w-100 font-weight-bold">Question: </span>
          <br />
          <input
            v-model="question.text"
            type="text"
            name="question"
            maxlength="400"
            placeholder="Write a question"
            class="fu-login__input"
            :disabled="isLoading"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>

        <div
          v-if="question.typeId === 2 || question.typeId === 3"
          class="d-flex flex-column align-items-start col-12 pl-5 mt-3"
        >
          <ol
            v-for="(option, index2) in question.answers"
            :key="'option-' + index2"
            class="col-12 px-0"
          >
            <li class="d-flex flex-wrap align-items-center col-7 px-0">
              <validation-provider
                tag="div"
                :name="`answer ${index2 + 1}`"
                rules="required"
                v-slot="{ errors }"
                class="col-10 px-0"
              >
                <input
                  v-model="option.text"
                  type="text"
                  name="answer"
                  placeholder="Write answer"
                  class="fu-login__input"
                  :disabled="isLoading"
                />
                <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
              </validation-provider>
              <button
                v-if="question.answers.length > 2"
                class="col-2 px-0 pt-3 multiselect__option-delete"
                @click="deleteChoice(question, index2)"
              >
                <i class="far fa-trash-alt"></i>
              </button>
            </li>
          </ol>
          <button
            v-if="question.typeId === 3"
            class="mt-1 fu-contact__button"
            @click="addNewChoice(question)"
          >
            <span><i class="far fa-plus mr-2"></i>Add choice</span>
          </button>
        </div>
      </div>
      <button
        class="d-flex justify-content-end w-100 mt-3 fu-contact__button"
        @click="addNewQuestion"
        :disabled="isLoading"
      >
        <span><i class="far fa-plus mr-2"></i>Add question</span>
      </button>
    </validation-observer>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center col-12">
        <button
          class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
          @click="saveQuestionnaire"
          :disabled="isLoading"
        >
          <span><i :class="`far fa-edit mr-2`"></i>Save</span>
        </button>
        <button
          class="btn btn-primary p-3"
          @click="closeSideBar"
          :disabled="isLoading"
        >
          <span><i class="far fa-times mr-2"></i>Cancel</span>
        </button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: "LeadBoardCreateQuestionnaireSidebar",
  data() {
    return {
      searchValue: "",
      isLoading: false,
      questionnaireId: null,
      questionnaire: {
        leadBoardId: null,
        name: null,
        questions: [
          {
            index: 0,
            typeId: 1,
            text: null,
            answers: [],
          },
        ],
      },
    };
  },
  watch: {
    questionnaireId() {
      if (this.questionnaireId)
        this.questionnaire = this.$lodash.cloneDeep(this.questionnaireId);
    },
  },
  computed: {
    questionTypes() {
      return this.$store.state.columnDeal.dealMeta.questionTypes;
    },
    questionnaires() {
      return this.$store.state.questionnaire.questionnaires;
    },
    leadBoardId() {
      return this.$store.state.columns.leadBoard.id;
    },
  },
  methods: {
    setQuestionType(question, type) {
      question.typeId = type;
      if (type === 2 || type === 3) {
        this.$set(question, "answers", [{ text: null }, { text: null }]);
      } else if (type === 1 && question.answers.length)
        this.$set(question, "answers", []);
    },
    closeSideBar() {
      this.$refs.createQuestionnaireSidebar.hide();
      this.questionnaireId = null;
      this.$nextTick(() => {
        this.questionnaire = this.$lodash.cloneDeep({
          leadBoardId: null,
          name: null,
          questions: [
            {
              index: 0,
              typeId: 1,
              text: null,
              answers: [],
            },
          ],
        });
      });
      this.$refs.questionnaireForm.reset();
    },
    changeQuestionPosition(question, direction) {
      const newPosition =
        direction === "up" ? question.index - 1 : question.index + 1;

      let previousQuestionIndex = this.questionnaire.questions.findIndex(
        (value) => value.index === newPosition
      );
      this.questionnaire.questions[previousQuestionIndex].index =
        question.index;
      question.index = newPosition;

      this.questionnaire.questions.sort((a, b) => {
        return a.index - b.index;
      });
    },
    deleteQuestion(index) {
      const conf = confirm("Do you to delete this question?");
      if (conf) {
        this.questionnaire.questions.splice(index, 1);
        this.questionnaire.questions.forEach((value, index) => {
          value.index = index;
        });
      }
    },
    addNewChoice(question) {
      question.answers.push({ text: "" });
    },
    deleteChoice(question, index) {
      const conf = confirm("Do you to delete this choice?");
      if (conf) question.answers.splice(index, 1);
    },
    addNewQuestion() {
      const length = this.questionnaire.questions.length;
      this.questionnaire.questions.push({
        index: length,
        typeId: 1,
        text: null,
        answers: [],
      });
    },
    createNewQuestionnaire() {
      this.questionnaireId = null;
      this.$nextTick(() => {
        this.questionnaire = this.$lodash.cloneDeep({
          leadBoardId: null,
          name: null,
          questions: [
            {
              index: 0,
              typeId: 1,
              text: null,
              answers: [],
            },
          ],
        });
      });
    },
    saveQuestionnaire() {
      this.$refs.questionnaireForm.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            this.questionnaire.leadBoardId = this.leadBoardId;

            const method = this.questionnaireId !== null ? "put" : "post";
            const res = await this.$axios[method](
              `${process.env.VUE_APP_API_URL}/api/lead-boards/${
                this.leadBoardId
              }/questionnaires${
                method === "put" ? `/${this.questionnaireId.id}` : ""
              }`,
              this.questionnaire
            );

            await this.$store.commit(
              "questionnaire/UPDATE_QUESTIONNAIRES_DATA",
              {
                data: res.data,
                id: method === "put" ? this.questionnaireId.id : null,
              }
            );

            await this.$bvToast.toast(
              `Questionnaire successfully ${
                method === "put" ? "updated" : "created"
              }`,
              {
                title: "Success",
                variant: "success",
                solid: true,
              }
            );

            this.closeSideBar();
          } catch (error) {
            console.error(error);
            this.$bvToast.toast(error.message, {
              title: "Creating questionnaire failed",
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
