<template>
  <div
    class="d-flex justify-content-between align-items-center card-header text-uppercase font-weight-bold py-1 px-3 fu-lead__header"
  >
    <p v-if="!editTitle" class="mb-0 text-uppercase mr-1">
      {{ columnTitle }}
    </p>

    <div v-else class="d-flex align-items-center col-8 px-0 h-100">
      <input
        ref="editTitleInput"
        v-model="newTitle"
        class="col-11 px-2 py-1 fu-lead__header-input kt-input-lead-column-title"
        @keyup.enter="saveColumnTitle"
        @keyup.esc="discardChanges"
        @blur="discardChanges"
      />
      <button
        class="col-2 px-0 ml-2 fu-lead__header-confirm kt-button-lead-column-accept"
        @click="saveColumnTitle"
      >
        <i class="far fa-check save"></i>
      </button>
      <button
        class="col-2 px-0 ml-2 fu-lead__header-confirm kt-button-lead-column-cancel"
        @click="discardChanges"
      >
        <i class="fal fa-times discard"></i>
      </button>
    </div>

    <div class="fu-lead__ellipsis">
      <b-dropdown
        class="m-md-2 kt-button-lead-column-dropdown"
        menu-class="p-0 fu-lead__header-dropdown"
      >
        <template #button-content
          ><i class="far fa-ellipsis-v fu-large"></i
        ></template>

        <b-dropdown-item link-class="p-0 fu-lead__ellipsis-dropdown">
          <button @click="renameColumnTitle">
            <i class="fal fa-pencil-alt mr-2"></i>Rename
          </button>
        </b-dropdown-item>
        <b-dropdown-item link-class="p-0 fu-lead__ellipsis-dropdown">
          <button @click="openColumnRulesSidebar">
            <i class="fas fa-exchange-alt mr-2"></i>Set Rule
          </button>
        </b-dropdown-item>
        <b-dropdown-item link-class="p-0 fu-lead__ellipsis-dropdown">
          <button @click="$emit('deleteColumn')">
            <i class="far fa-trash-alt mr-2"></i>Delete
          </button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeadBoardColumnHeader",
  props: {
    leadBoardId: {
      type: Number,
      required: true,
    },
    columnId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    columnPosition: {
      type: Number,
      required: true,
    },
    dealsLength: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newTitle: "",
      editTitle: false,
    };
  },
  computed: {
    columnTitle() {
      return `${this.title} ${this.dealsLength ? `(${this.dealsLength})` : ""}`;
    },
  },
  watch: {
    title() {
      this.newTitle = this.title;
    },
  },
  methods: {
    renameColumnTitle() {
      this.newTitle = this.title;
      this.editTitle = true;
      setTimeout(() => {
        this.$refs.editTitleInput.focus();
      }, 10);
    },
    discardChanges() {
      setTimeout(() => {
        this.editTitle = false;
      }, 100);
    },
    async saveColumnTitle() {
      if (this.newTitle !== this.title) {
        await this.$store.dispatch("columns/updateColumnTitle", {
          boardId: this.leadBoardId,
          columnId: this.columnId,
          newTitle: this.newTitle,
          position: this.columnPosition,
        });

        this.$bvToast.toast("Column title has changed", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }
      this.editTitle = false;
    },
    openColumnRulesSidebar() {
      const column = this.$store.state.columns.columns.find(
        ({ id }) => id === this.columnId
      );
      this.$store.commit("columnRule/SET_COLUMN_ID", column.id);
      this.$store.commit("columnRule/SET_COLUMN_RULES", column.rules);
      this.$root.$emit("bv::toggle::collapse", "rulesSidebar");
    },
  },
};
</script>
