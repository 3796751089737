<template>
  <div
    class="d-flex flex-wrap align-items-center mx-2 my-2 py-2 fu-lead__button-add-card"
  >
    <button
      class="d-flex align-items-center w-100 py-1 px-2 fu-button__transparent kt-button-lead-column-create-issue"
      @click="$emit('addNewColumnDeal', true)"
    >
      <i class="fal fa-plus fu-large mr-2"></i>
      <span>Create deal</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "LeadBoardColumnFooter",
};
</script>
