<template>
  <div class="fu-lead__inner-card-wrapper">
    <div
      v-for="(value, index) in columnCards"
      :key="index"
      class="card-body mx-2 mt-2 mb-2 p-3 fu-lead__inner-card"
      @click="viewDealDetails(value)"
    >
      <div class="d-flex justify-content-between mb-3">
        <h5 class="card-title mb-0">{{ value.contactPerson }}</h5>

        <div class="d-flex align-items-center">
          <span class="fu-deal__column-todo_items">{{
            todoItemsStatistics(value.todoItems)
          }}</span>
          <i class="fal fa-clipboard-list-check ml-1"></i>
        </div>
      </div>

      <p class="mb-0 fu-lead__inner-card-text">Contact: {{ contact(value) }}</p>

      <div class="d-flex justify-content-between align-items-start w-100">
        <div class="d-flex align-items-center">
          <p class="mb-0 fu-lead__inner-card-text">
            Company: {{ value.client ? value.client.name : "" }}
          </p>
        </div>
        <div class="fu-lead__button-move">
          <button
            v-if="columnIndex !== 0"
            class="fu-button__transparent left kt-button-lead-card-move-left"
            @click.stop="moveDeal('left', value)"
          >
            <i class="far fa-arrow-alt-circle-left"></i>
          </button>
          <button
            v-if="columnIndex !== columns.length - 1"
            class="fu-button__transparent right kt-button-lead-card-move-right"
            @click.stop="moveDeal('right', value)"
          >
            <i class="far fa-arrow-alt-circle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeadBoardColumnCard",
  props: {
    columnIndex: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    columnCards: {
      type: Array,
      required: true,
    },
    cardPosition: {
      type: Number,
      required: true,
    },
    leadBoardId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    contact(value) {
      return value.email ? value.email : value.phone;
    },
    todoItemsStatistics(value) {
      if (value.length) {
        let i = 0;
        value.forEach((value) => {
          if (value.isChecked) i++;
        });
        return `${i}/${value.length}`;
      }
      return "0";
    },
    async viewDealDetails(deal) {
      await this.$store.commit("columnDeal/SET_DEAL_DATA", deal);
      await this.$router.push({
        name: "LeadBoardDeal",
        params: { id: this.$route.params.id, dealId: deal.id },
      });
    },
    async moveDeal(direction, cardData) {
      const newPosition =
        direction === "right" ? this.cardPosition + 1 : this.cardPosition - 1;

      const oldColumn = this.columns.find(
        (value) => value.position === this.cardPosition
      );
      try {
        const index = oldColumn.deals.findIndex(
          (card) => card.id === cardData.id
        );
        const newColumn = this.columns.find(
          (value) => value.position === newPosition
        );

        cardData["columnId"] = newColumn.id;
        cardData.questionnaire = cardData.questionnaire
          ? cardData.questionnaire
          : (cardData.questionnaire = { questions: [] });

        const res = await this.$axios.put(
          `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/deals/${cardData.id}`,
          cardData
        );
        oldColumn.deals.splice(index, 1);
        newColumn.deals.push(res.data);
        this.$emit("scrollColumn", newColumn.id);

        this.$bvToast.toast(`Deal moved to column ${newColumn.name}`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: "Switching deal failed",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>
