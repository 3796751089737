var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"createQuestionnaireSidebar",attrs:{"id":"createQuestionnaireSidebar","right":"","backdrop":"","sidebar-class":"fu-sidebar fu-sidebar__user","header-class":"fu-sidebar__header","body-class":"fu-sidebar__body flex-column","footer-class":"fu-sidebar__footer"},on:{"hidden":_vm.closeSideBar},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("Create questionnaire")]),_c('i',{staticClass:"far fa-times fa-1x",on:{"click":_vm.closeSideBar}})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end align-items-center col-12"},[_c('button',{staticClass:"btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.saveQuestionnaire}},[_c('span',[_c('i',{class:"far fa-edit mr-2"}),_vm._v("Save")])]),_c('button',{staticClass:"btn btn-primary p-3",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.closeSideBar}},[_c('span',[_c('i',{staticClass:"far fa-times mr-2"}),_vm._v("Cancel")])])])]},proxy:true}])},[_c('validation-observer',{ref:"questionnaireForm",staticClass:"d-flex flex-wrap col-12 px-0 mt-3"},[_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-between col-12 px-0"},[_c('validation-provider',{staticClass:"col-6 px-0 pr-lg-3 pr-0",attrs:{"tag":"div","name":"questionnaire"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{staticClass:"multiselect",class:{
            multiselect__disabled: _vm.isLoading,
          },attrs:{"options":_vm.questionnaires,"loading":_vm.isLoading,"label":"name","track-by":"name","placeholder":"Select questionnaire","disabled":_vm.isLoading},on:{"search-change":function($event){_vm.searchValue = $event}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('span',{staticClass:"kt-select-item"},[_vm._v(_vm._s(props.option.name))])]}}],null,true),model:{value:(_vm.questionnaireId),callback:function ($$v) {_vm.questionnaireId=$$v},expression:"questionnaireId"}},[_c('template',{slot:"placeholder"},[_vm._v(" Select questionnaire ")]),_c('template',{slot:"noResult"},[_c('span',[_vm._v("No country found: \""+_vm._s(_vm.searchValue)+"\" ")])])],2),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('button',{staticClass:"fu-contact__button",on:{"click":_vm.createNewQuestionnaire}},[_vm._v(" Create new ")])],1),_c('validation-provider',{staticClass:"col-12 px-0 mt-3 mb-4",attrs:{"tag":"div","name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Title *")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionnaire.name),expression:"questionnaire.name"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"title","placeholder":"Title","disabled":_vm.isLoading},domProps:{"value":(_vm.questionnaire.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.questionnaire, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),_vm._l((_vm.questionnaire.questions),function(question,index){return _c('div',{key:index,staticClass:"d-flex flex-wrap align-items-center col-12 px-0 mb-4 fu-border__lightgrey-top"},[_c('div',{staticClass:"d-flex flex-wrap align-items-center col-12 px-0"},[_c('validation-provider',{staticClass:"col-6 px-0 mt-0",attrs:{"tag":"div","name":"question type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Question type")]),_c('br'),_c('select',{staticClass:"pl-3 text-capitalize fu-login__input fu-select",class:{ 'fu-select__disabled': _vm.isLoading },attrs:{"disabled":_vm.isLoading},domProps:{"value":question.typeId},on:{"change":function($event){_vm.setQuestionType(question, Number($event.target.value))}}},_vm._l((_vm.questionTypes),function(option){return _c('option',{key:option.id,staticClass:"text-capitalize",domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex flex-column align-items-end justify-content-between col-6 px-0 pt-3"},[_c('button',{staticClass:"mb-2 fu-button__transparent fu-button__position",attrs:{"disabled":question.index === 0},on:{"click":function($event){return _vm.changeQuestionPosition(question, 'up')}}},[_c('i',{staticClass:"far fa-chevron-up"})]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.questionnaire.questions.length > 1),expression:"questionnaire.questions.length > 1"}],staticClass:"fu-button__transparent fu-button__position",on:{"click":function($event){return _vm.deleteQuestion(index)}}},[_c('i',{staticClass:"far fa-trash-alt"})]),_c('button',{staticClass:"mt-2 fu-button__transparent fu-button__position",attrs:{"disabled":question.index === _vm.questionnaire.questions.length - 1},on:{"click":function($event){return _vm.changeQuestionPosition(question, 'down')}}},[_c('i',{staticClass:"far fa-chevron-down"})])])],1),_c('validation-provider',{staticClass:"col-12 px-0 mt-1",attrs:{"tag":"div","name":("question " + (index + 1)),"rules":"required|max:400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Question: ")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(question.text),expression:"question.text"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"question","maxlength":"400","placeholder":"Write a question","disabled":_vm.isLoading},domProps:{"value":(question.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(question, "text", $event.target.value)}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(question.typeId === 2 || question.typeId === 3)?_c('div',{staticClass:"d-flex flex-column align-items-start col-12 pl-5 mt-3"},[_vm._l((question.answers),function(option,index2){return _c('ol',{key:'option-' + index2,staticClass:"col-12 px-0"},[_c('li',{staticClass:"d-flex flex-wrap align-items-center col-7 px-0"},[_c('validation-provider',{staticClass:"col-10 px-0",attrs:{"tag":"div","name":("answer " + (index2 + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.text),expression:"option.text"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"answer","placeholder":"Write answer","disabled":_vm.isLoading},domProps:{"value":(option.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(option, "text", $event.target.value)}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(question.answers.length > 2)?_c('button',{staticClass:"col-2 px-0 pt-3 multiselect__option-delete",on:{"click":function($event){return _vm.deleteChoice(question, index2)}}},[_c('i',{staticClass:"far fa-trash-alt"})]):_vm._e()],1)])}),(question.typeId === 3)?_c('button',{staticClass:"mt-1 fu-contact__button",on:{"click":function($event){return _vm.addNewChoice(question)}}},[_c('span',[_c('i',{staticClass:"far fa-plus mr-2"}),_vm._v("Add choice")])]):_vm._e()],2):_vm._e()],1)}),_c('button',{staticClass:"d-flex justify-content-end w-100 mt-3 fu-contact__button",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.addNewQuestion}},[_c('span',[_c('i',{staticClass:"far fa-plus mr-2"}),_vm._v("Add question")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }