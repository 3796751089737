<template>
  <b-sidebar
    id="rulesSidebar"
    ref="rulesSidebar"
    right
    backdrop
    sidebar-class="fu-sidebar fu-sidebar__user"
    header-class="fu-sidebar__header"
    body-class="fu-sidebar__body flex-column"
    footer-class="fu-sidebar__footer"
    @hidden="closeSideBar"
    @shown="showSidebar"
  >
    <template #header>
      <span>Set rules</span>
      <i class="far fa-times fa-1x" @click="closeSideBar"></i>
    </template>

    <div
      v-if="!rules.length"
      class="d-flex flex-column align-items-center justify-content-center"
      style="height: calc(100vh - 200px)"
    >
      <h4>This column doesn't have any rule</h4>
      <button
        class="fu-contact__button kt-button-lead-column-add-rule"
        @click="addNewColumnRule"
        :disabled="isLoading"
      >
        <span><i class="far fa-plus mr-2"></i>Add new</span>
      </button>
    </div>

    <validation-observer
      ref="rulesSidebarForm"
      class="d-flex flex-wrap col-12 px-0"
    >
      <div v-if="rules.length" class="w-100 mt-3">
        <div
          v-for="(value, index) in rules"
          :key="index"
          class="w-100"
          :class="{ 'mt-4 fu-border__lightgrey-top': index !== 0 }"
        >
          <div
            class="d-flex flex-wrap align-items-center justify-content-between col-12 px-0"
            :class="{ 'mt-3': index !== 0 }"
          >
            <h5>Rule #{{ index + 1 }}</h5>
            <button
              class="col-lg-1 col-1 px-0 multiselect__option-delete fu-large kt-button-lead-column-remove-rule"
              @click="deleteColumnRule(index)"
              :disabled="isLoading"
            >
              <i class="far fa-trash-alt ml-lg-3 ml-0"></i>
            </button>
          </div>

          <div class="d-flex align-items-center col-12 px-0 mt-2">
            <span class="col-2 p-0 font-weight-bold">Active: </span>
            <b-form-checkbox
              v-model="value.isDisabled"
              name="is-archived"
              size="lg"
              switch
              class="ml-4 mb-3 fu-checkbox kt-checkbox-lead-column-active-rule"
              :disabled="isLoading"
            >
            </b-form-checkbox>
            <span class="mb-0 ml-5">{{
              value.isDisabled ? "Active" : "Disabled"
            }}</span>
          </div>

          <validation-provider
            tag="div"
            :name="`Rule type ${index + 1}`"
            rules="required"
            class="d-flex flex-wrap align-items-center col-12 px-0 mt-2"
            v-slot="{ errors }"
          >
            <span class="w-100 font-weight-bold">Rule type: *</span>
            <br />
            <select
              v-model="value.typeId"
              class="fu-login__input kt-select-lead-rule-type"
              :class="{ 'fu-select__disabled': isLoading }"
              :disabled="isLoading"
            >
              <option
                v-for="option in ruleType"
                :key="option.id"
                :value="option.id"
                class="kt-select-lead-rule-type-option"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            tag="div"
            :name="`Goes to column ${index + 1}`"
            rules="required"
            v-slot="{ errors }"
            class="d-flex flex-wrap align-items-center col-12 px-0 mt-3"
          >
            <span class="w-100 font-weight-bold"
              >If deal goes to column: *</span
            >
            <br />
            <multiselect
              v-model="value.triggerColumn"
              :options="columns"
              :loading="isLoading"
              label="name"
              track-by="id"
              placeholder="Column"
              class="multiselect"
              :class="{ multiselect__disabled: isLoading }"
              :disabled="isLoading"
              @search-change="searchValue = $event"
            >
              <template slot="placeholder"> Column </template>

              <template slot="option" slot-scope="props">
                <span class="kt-select-item">{{ props.option.name }}</span>
              </template>

              <template slot="noResult">
                <span>No position found: "{{ searchValue }}" </span>
              </template>
            </multiselect>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            tag="div"
            :name="`Sequence ${index + 1}`"
            rules="required"
            v-slot="{ errors }"
            class="d-flex flex-wrap align-items-center col-12 px-0 mt-3"
          >
            <span class="w-100 font-weight-bold"
              >Add it to mailerlite sequence: *</span
            >
            <br />
            <multiselect
              v-model="value.campaign"
              :options="campaigns"
              :loading="isLoading"
              label="campaignName"
              track-by="id"
              placeholder="Column"
              class="multiselect"
              :class="{ multiselect__disabled: isLoading }"
              :disabled="isLoading"
              @search-change="searchValue = $event"
            >
              <template slot="placeholder"> Sequence </template>

              <template slot="option" slot-scope="props">
                <span class="kt-select-item">{{
                  props.option.campaignName
                }}</span>
              </template>

              <template slot="noResult">
                <span>No position found: "{{ searchValue }}" </span>
              </template>
            </multiselect>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="d-flex justify-content-end col-12 px-0 my-3">
          <button
            ref="addNewRule"
            class="fu-contact__button kt-button-lead-column-add-rule"
            @click="addNewColumnRule"
            :disabled="isLoading"
          >
            <span><i class="far fa-plus mr-2"></i>Add new</span>
          </button>
        </div>
      </div>
    </validation-observer>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center col-12">
        <button
          id="kt-button-lead-column-sidebar-save"
          class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
          @click="saveRules"
          :disabled="isLoading"
        >
          <span><i :class="`far fa-edit mr-2`"></i>Save</span>
        </button>
        <button
          id="kt-button-lead-column-sidebar-cancel"
          class="btn btn-primary p-3"
          @click="closeSideBar"
          :disabled="isLoading"
        >
          <span><i class="far fa-times mr-2"></i>Cancel</span>
        </button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: "LeadBoardColumnRuleSidebar",
  data() {
    return {
      searchValue: "",
      isLoading: false,
      rules: [],
    };
  },
  computed: {
    ruleType() {
      return this.$store.state.columnDeal.dealMeta.emailRuleTypes;
    },
    columns() {
      return this.$store.state.columns.columns;
    },
    campaigns() {
      return this.$store.state.columnDeal.dealMeta.campaigns;
    },
    leadBoardId() {
      return this.$store.state.columns.leadBoard.id;
    },
    columnId() {
      return this.$store.state.columnRule.columnId;
    },
  },
  methods: {
    showSidebar() {
      this.rules = this.$lodash.cloneDeep(this.$store.state.columnRule.rules);
    },
    closeSideBar() {
      this.$refs.rulesSidebar.hide();
      if (this.rules.length) this.$refs.rulesSidebarForm.reset();
      this.rules = this.$lodash.cloneDeep([]);
      this.searchValue = "";
    },
    deleteColumnRule(index) {
      const conf = confirm("Do you want to delete this rule?");
      if (conf) this.rules.splice(index, 1);
    },
    async addNewColumnRule() {
      await this.rules.push({
        isDisabled: false,
        typeId: null,
        triggerColumnId: null,
        campaignId: null,
      });
      const wrapper = document.getElementsByClassName("fu-sidebar__body");
      wrapper[0].scrollTo(0, wrapper[0].scrollHeight);
    },

    async saveRules() {
      const index = this.$store.state.columns.columns.findIndex(
        ({ id }) => id === this.columnId
      );
      /*TODO: REMOVE THIS IF AND CREATE ONLY ONE POST REQUEST*/
      this.$refs.rulesSidebarForm.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          try {
            this.rules.map((element) => {
              element.campaignId = element.campaign.id;
              element.triggerColumnId = element.triggerColumn.id;
            });

            await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/columns/${this.columnId}/email-rules`,
              this.rules
            );
            await this.$store.commit("columns/UPDATE_COLUMN_RULES", {
              index,
              rules: this.rules,
            });
            this.$bvToast.toast(
              this.rules
                ? "Rule were added successfully"
                : "Rule action successful",
              {
                title: "Success",
                variant: "success",
                solid: true,
              }
            );

            this.closeSideBar();
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Rules were not added", {
              title: "Adding rules failed",
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
