<template>
  <b-sidebar
    id="createDealSidebar"
    ref="createDealSidebar"
    right
    backdrop
    sidebar-class="fu-sidebar fu-sidebar__user"
    header-class="fu-sidebar__header"
    body-class="fu-sidebar__body flex-column"
    footer-class="fu-sidebar__footer"
    @hidden="closeSideBar"
  >
    <template #header>
      <span>Create deal</span>
      <i class="far fa-times fa-1x" @click="closeSideBar"></i>
    </template>

    <validation-observer ref="dealSidebarForm">
      <lead-board-deal-form
        :is-loading="isLoading"
        form-class="d-flex flex-wrap col-12 px-0 mt-3"
      ></lead-board-deal-form>
    </validation-observer>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center col-12">
        <button
          class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
          @click="saveDeal"
          :disabled="isLoading"
        >
          <span><i :class="`far fa-edit mr-2`"></i>Save</span>
        </button>
        <button
          class="btn btn-primary p-3"
          @click="closeSideBar"
          :disabled="isLoading"
        >
          <span><i class="far fa-times mr-2"></i>Cancel</span>
        </button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import LeadBoardDealForm from "@/components/lead-board/gadgets/LeadBoardDealForm";
export default {
  name: "LeadBoardCreateDealSidebar",
  components: { LeadBoardDealForm },
  props: {
    selectedDealOwner: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    leadBoardId() {
      return this.$store.state.columns.leadBoard.id;
    },
    columnId() {
      return this.$store.getters["columnDeal/getDealFieldData"]("columnId");
    },
    columns() {
      return this.$store.state.columnDeal.dealMeta.columns;
    },
  },
  methods: {
    addNewDeal(deal) {
      const index = this.columns.findIndex(({ id }) => id === this.columnId);
      this.$store.commit("columns/UPDATE_COLUMN_DEALS", { index, deal });
    },

    saveDeal() {
      this.$refs.dealSidebarForm.validate().then(async (success) => {
        const deal = this.$store.state.columnDeal.deal;
        if (success) {
          this.isLoading = true;
          try {
            deal["columnId"] = this.columnId;
            const res = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/deals`,
              deal
            );

            this.$bvToast.toast("Success", {
              title: `Lead board deal was successfully created`,
              variant: "success",
              solid: true,
            });

            if (
              deal.ownerId === this.selectedDealOwner ||
              (!deal.ownerId && this.selectedDealOwner < 0) ||
              (deal.ownerId && this.selectedDealOwner < 0)
            )
              await this.addNewDeal(res.data);
            this.closeSideBar();
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Failed", {
              title: `Creat lead board deal failed`,
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    closeSideBar() {
      this.$refs.createDealSidebar.hide();
      this.$refs.dealSidebarForm.reset();
      this.$store.commit("columnDeal/RESET_DEAL_DATA");
      this.$emit("resetColumnId");
    },
  },
};
</script>
