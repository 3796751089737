<template>
  <div
    class="d-flex flex-wrap justify-content-between align-items-center col-12 px-0 mt-3"
  >
    <div class="col-lg-4 col-12 px-0">
      <h3 class="mb-0">{{ leadBoardTitle }}</h3>
    </div>

    <div
      v-if="columns.length"
      class="d-flex flex-wrap justify-content-lg-end justify-content-between align-items-center col-lg-8 col-12 px-0 mt-lg-0 mt-3"
    >
      <multiselect
        v-model="dealsFrom"
        :options="users"
        :custom-label="memberFullName"
        track-by="id"
        placeholder="Deal owner"
        class="col-lg-2 col-md-6 px-0 mt-0 mr-lg-3 mr-0 multiselect kt-div-input"
        @search-change="searchValue = $event"
      >
        <template slot="placeholder"> Deal owner </template>

        <template slot="option" slot-scope="props">
          <span class="kt-select-item">{{
            `${props.option.firstName} ${
              props.option.lastName ? props.option.lastName : ""
            }`
          }}</span>
        </template>

        <template slot="noResult">
          <span>No position found: "{{ searchValue }}" </span>
        </template>
      </multiselect>
      <fu-button
        id="copyIframe"
        title="Copy iframe </>"
        class="mt-lg-0 mt-3 mr-3 fu-button__lead-header"
        :disabled="isLoading"
        @click="copyIframe"
      >
      </fu-button>

      <fu-button
        v-if="apiEnabled"
        id="manualFetchData"
        title="Fetch"
        class="mt-lg-0 mt-3 mr-2 fu-button__lead-header"
        :disabled="isLoading"
        :is-loading="isLoading"
        @click="manualFetchData()"
      >
        <template #button-icon>
          <i class="far fa-sync-alt mr-2"></i>
        </template>
      </fu-button>

      <fu-button
        id="createQuestionnaire"
        title="Create Questionnaire"
        class="mt-lg-0 mt-3 fu-button__lead-header"
        :disabled="isLoading"
        @click="$emit('openQuestionnaireSidebar')"
      >
      </fu-button>

      <fu-button
        id="createDeal"
        title="Create Deal"
        class="ml-2 mt-lg-0 mt-3 fu-search-bar__create-button fu-button__lead-header"
        :disabled="!columns.length || isLoading"
        @click="$emit('openCreateDealSidebar')"
      >
      </fu-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeadBoardHeader",
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      searchValue: "",
      dealsFrom: null,
    };
  },
  computed: {
    iframeSnippet() {
      return (
        '<iframe src="https://crm.4futureagency.com/lead-board/public/' +
        this.leadBoardId +
        '/deal"></iframe>'
      );
    },
    leadBoardTitle() {
      return this.$store.state.columns.leadBoard.title;
    },
    leadBoardId() {
      return this.$store.state.columns.leadBoard.id;
    },
    apiEnabled() {
      return this.$store.state.columns.leadBoard.apiEnabled;
    },
    users() {
      return this.$store.state.columnDeal.dealOwners;
    },
  },
  watch: {
    async dealsFrom(value) {
      try {
        let res;

        if (value) {
          const ownerId = value.id > 0 ? value.id : "";
          const hasFilter = value.id === -1 ? "0" : "1";
          res = await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/columns?ownerId=${ownerId}&hasFilter=${hasFilter}`
          );
        } else {
          res = await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/columns?ownerId=&hasFilter=0`
          );
        }
        this.$store.commit("columns/SET_LEAD_BOARD_COLUMNS", res.data);
        this.$emit("dealOwner", value ? value.id : -1);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: "Fetching lead board columns failed",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  methods: {
    copyIframe() {
      navigator.clipboard.writeText(this.iframeSnippet);
    },
    memberFullName({ firstName, lastName }) {
      return `${firstName} ${lastName ? lastName : ""}`;
    },
    async manualFetchData() {
      try {
        this.isLoading = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.leadBoardId}/fetch`
        );
        this.$store.commit("columnDeal/SET_DEAL_META", res.data.meta);
        this.$store.commit("columns/SET_LEAD_BOARD_COLUMNS", res.data.columns);
        this.$bvToast.toast(`Data was fetched`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: "Manual fetching data failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
